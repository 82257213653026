// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---plugins-about-src-pages-about-js": () => import("./../../../plugins/about/src/pages/about.js" /* webpackChunkName: "component---plugins-about-src-pages-about-js" */),
  "component---plugins-applicant-and-candidate-privacy-notice-src-pages-applicant-and-candidate-privacy-notice-js": () => import("./../../../plugins/applicant-and-candidate-privacy-notice/src/pages/applicant-and-candidate-privacy-notice.js" /* webpackChunkName: "component---plugins-applicant-and-candidate-privacy-notice-src-pages-applicant-and-candidate-privacy-notice-js" */),
  "component---plugins-funding-support-src-pages-funding-support-js": () => import("./../../../plugins/funding-support/src/pages/funding-support.js" /* webpackChunkName: "component---plugins-funding-support-src-pages-funding-support-js" */),
  "component---plugins-grants-src-pages-grants-js": () => import("./../../../plugins/grants/src/pages/grants.js" /* webpackChunkName: "component---plugins-grants-src-pages-grants-js" */),
  "component---plugins-home-src-pages-index-js": () => import("./../../../plugins/home/src/pages/index.js" /* webpackChunkName: "component---plugins-home-src-pages-index-js" */),
  "component---plugins-legal-disclosures-src-pages-legal-disclosures-js": () => import("./../../../plugins/legal-disclosures/src/pages/legal-disclosures.js" /* webpackChunkName: "component---plugins-legal-disclosures-src-pages-legal-disclosures-js" */),
  "component---plugins-press-src-templates-press-article-press-article-js": () => import("./../../../plugins/press/src/templates/PressArticle/PressArticle.js" /* webpackChunkName: "component---plugins-press-src-templates-press-article-press-article-js" */),
  "component---plugins-press-src-templates-press-js": () => import("./../../../plugins/press/src/templates/press.js" /* webpackChunkName: "component---plugins-press-src-templates-press-js" */),
  "component---plugins-privacy-and-cookies-src-pages-privacy-and-cookies-js": () => import("./../../../plugins/privacy-and-cookies/src/pages/privacy-and-cookies.js" /* webpackChunkName: "component---plugins-privacy-and-cookies-src-pages-privacy-and-cookies-js" */),
  "component---plugins-projects-src-pages-projects-js": () => import("./../../../plugins/projects/src/pages/projects.js" /* webpackChunkName: "component---plugins-projects-src-pages-projects-js" */),
  "component---plugins-research-src-pages-research-js": () => import("./../../../plugins/research/src/pages/research.js" /* webpackChunkName: "component---plugins-research-src-pages-research-js" */),
  "component---plugins-security-report-src-pages-security-report-js": () => import("./../../../plugins/security-report/src/pages/security-report.js" /* webpackChunkName: "component---plugins-security-report-src-pages-security-report-js" */),
  "component---src-w-3-f-gatsby-theme-w-3-f-pages-404-js": () => import("./../../../src/@w3f/gatsby-theme-w3f/pages/404.js" /* webpackChunkName: "component---src-w-3-f-gatsby-theme-w-3-f-pages-404-js" */)
}

